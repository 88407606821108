<template>
    <div ref="userDetail" class="user-detail" @scroll="onScroll">
        <Loading :loading="loading" />
        <template v-if="user">
            <UserDetailHeaderBar
                :from="from"
                :user="user"
                :userId="userId"
                :show="showHiddenHeader"
                :nickname="user.profile.nickname"
                :photos="userPhotos"
            />
            <SocialMeetProfileBase :user="user" :profile="profile" :photos="userPhotos" :status="status" />
            <VerificationBadges
                :from="from"
                v-if="verificationBadges.length"
                :verificationBadges="verificationBadges"
            />
            <BasicProfile v-if="profile" :userId="userId" :profile="profile" :verificationBadges="verificationBadges" />
            <template v-if="user.keywords.length > 0">
                <Keywords :keywords="user.keywords" />
            </template>
        </template>
    </div>
</template>

<script>
import UserDetailHeaderBar from '@/routes/user-detail/components/UserDetailHeaderBar'
import SocialMeetProfileBase from '@/routes/social-meeting/components/SocialMeetProfileBase'
import VerificationBadges from '@/routes/user-detail/components/VerificationBadges'
import BasicProfile from '@/routes/user-detail/components/BasicProfile'
import Keywords from '@/routes/user-detail/components/Keywords'

export default {
    name: 'SocialMeetProfilePage',
    props: {
        from: String,
        photo: String,
        status: {
            type: String,
            required: false,
        },
        userId: {
            type: Number,
            required: true,
        },
    },
    components: {
        UserDetailHeaderBar,
        SocialMeetProfileBase,
        VerificationBadges,
        BasicProfile,
        Keywords,
    },
    data: () => ({
        user: null,
        userPhotos: [],
        showHiddenHeader: false,
        loading: false,
    }),
    created() {
        this.init()
    },
    computed: {
        dom() {
            return this.$refs.userDetail
        },
        profile() {
            return this.user.profile
        },
        verificationBadges() {
            return (this.user.verification_badges || []).filter(
                ({ confirmed, show_profile: show, category }) => confirmed && show && category !== 'attraction',
            )
        },
    },
    methods: {
        async init() {
            if (!this.userId) {
                this.$stackRouter.pop()
                return
            }

            this.loading = true
            try {
                this.user = await this.$store.dispatch('loadUserDetail', {
                    userId: this.userId,
                    agentId: null,
                })
            } catch (e) {
                this.$toast.error(e.data)
                this.$stackRouter.pop()
                return
            } finally {
                this.loading = false
            }

            // 사진 배열에 저장
            if (this.user.photos) this.userPhotos = [...this.user.photos]
            else this.userPhotos = [this.photo]
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > window.innerWidth - 56 // 56 is @header-height
        },
    },
}
</script>

<style scoped lang="scss">
.user-detail {
    ::v-deep .header-bar {
        i {
            color: black !important;
        }
        .right {
            display: none;
        }
    }
    ::v-deep .basic-profile {
        padding: 32px 16px;
        border-bottom: 1px solid $grey-02;
    }
    ::v-deep .keywords {
        padding: 32px 16px;
    }
}
</style>
