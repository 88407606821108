<template>
    <div class="social-profile">
        <img class="mini-photo" :src="photos[0].url || photos[0]" alt="" />
        <div class="profile-infos">
            <div v-if="status === 'chatroom_opened'">
                <div class="f-18">
                    <span class="f-medium">{{ user.name }}</span>
                    <span>{{ profile.nickname }}</span>
                </div>
            </div>
            <div class="name-nickname">
                <div class="f-18 f-medium">
                    <span>{{ profile.nickname }}</span>
                    <span
                        class="gender-badge"
                        :class="!user.gender ? 'male' : 'female'"
                        v-html="user.gender === 0 ? '남성' : '여성'"
                    />
                </div>
            </div>
            <div class="age-height-location m-t-10">
                <div class="age-height">
                    <span v-html="`${$options.filters.asAge(profile.birthday)}세`" />
                    <span class="divider" v-html="'ㅣ'" />
                    <span v-html="`${profile.height}cm`" />
                </div>
                <div class="location flex-row m-t-8">
                    <div class="m-r-4" v-html="profile.region_name" />
                    <Distance :distance="$distance($store.getters.me.location, user.location)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialMeetProfileBase',
    props: {
        user: {
            type: Object,
            required: true,
        },
        profile: {
            type: Object,
            required: true,
        },
        photos: Array,
        status: {
            type: String,
            required: false,
        },
    },
}
</script>

<style scoped lang="scss">
.social-profile {
    display: flex;
    align-items: flex-end;
    padding: 16px 16px 0 16px;
    margin-top: 52px;

    .mini-photo {
        width: 80px;
        height: 80px;
        border-radius: 32px;
        opacity: 0.9;
        margin-right: 24px;
    }
    .profile-infos {
        color: black;

        .name-nickname {
            .gender-badge {
                font-size: 12px;
                padding: 2px 8px;
                white-space: nowrap;
                margin-left: 8px;
                border-radius: 6px;
                opacity: 0.9;
                color: white;

                &.female {
                    background: $pink-woman;
                }
                &.male {
                    background: $blue-man;
                }
            }
        }
    }
}
</style>
